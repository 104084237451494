import React from 'react'
import ukflag from '../assets/student.png'
import { Button } from 'react-scroll'
import vid from '../assets/new.webm'

function Hero() {

  const handleButtonClick = () => {
    window.open('https://app.theworksheep.com', '_blank');
  };

  return (
    <div>
        
        <div className='max-w-[800px] w-full h-screen mx-auto text-center flex flex-col justify-center mt-16 mb-9'>
        <div className='flex items-center justify-center mt-8'>
          <div className='mx-auto text-center flex flex-row justify-center'>
        <p className='font-bold'>AI-Powered Worksheets Tailored to your Students' Needs!📝</p>
        {/* <img className='w-[24px] h-[24px] ml-1' src={ukflag} alt="UK flag"/> */}
        </div>
    </div>
            <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 text-green-700'>Create Math Worksheets in Seconds.</h1>
            <div>
                <p>Easy, Fast & Flexible for all Math Teachers!</p>
                <Button className='bg-black w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white' onClick={handleButtonClick} 
      spy={true} 
      smooth={true} 
      offset={50} 
      duration={500}>
        Start Now for Free!
      </Button>
            </div>

{/* Video Section */}
<div className="mt-12 relative">
  {/* Ribbon */}
  <div className="bg-gray-800 text-white p-2 flex justify-between items-center rounded-t-lg shadow-lg max-w-[90%] mx-auto">
    <div className="flex space-x-2">
      <button className="bg-red-600 w-3 h-3 rounded-full hover:bg-red-500" title="Close"></button>
      <button className="bg-yellow-400 w-3 h-3 rounded-full hover:bg-yellow-300" title="Minimize"></button>
      <button className="bg-green-600 w-3 h-3 rounded-full hover:bg-green-500" title="Maximize"></button>
    </div>
  </div>

  <video 
    className="w-full h-auto max-w-[90%] mx-auto md:max-w-[720px] shadow-lg mt-[-4px] mb-12" 
    src={vid} 
    autoPlay 
    muted 
    loop 
    playsInline
  />
</div>



        </div>

    </div>
  )
}

export default Hero