import React from 'react'
import Layout from './Layout'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import down from '../assets/down.svg'
import { faqs } from './faq';

const Pricing = () => {
  return (
    <Layout>
      <div className="bg-[#71d694] min-h-screen flex items-center justify-center px-4 pb-8">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl text-center">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">
            Flexible Pricing Plans
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Get more value with bulk credits - Save up to 33%!
          </p>

          <div className="grid md:grid-cols-3 gap-6 mb-12">
            {/* Starter Tier */}
            <div className="bg-green-50 p-6 rounded-xl border-2 border-green-100">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Starter</h2>
              <div className="text-4xl font-bold text-green-600 mb-2">$2.99</div>
              <div className="text-gray-600 mb-4">10 Credits</div>
              <div className="text-sm text-gray-500 mb-4">($0.30 per worksheet)</div>
              <a href="https://app.theworksheep.com/buycredits" target="_blank" rel="noopener noreferrer">
              <button className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition">
                Get Started
              </button>
              </a>
            </div>

            {/* Popular Tier */}
            <div className="bg-green-100 p-6 rounded-xl border-2 border-green-300 transform scale-105">
              <span className="bg-green-600 text-white px-3 py-1 rounded-full text-sm mb-2 inline-block">
                Most Popular
              </span>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Standard</h2>
              <div className="text-4xl font-bold text-green-600 mb-2">$4.99</div>
              <div className="text-gray-600 mb-4">20 Credits</div>
              <div className="text-sm text-gray-500 mb-4">($0.25 per worksheet)</div>
              <div className="text-green-700 text-sm mb-3">
                Save $1 vs 2x Starter packs
              </div>
              <a href="https://app.theworksheep.com/buycredits" target="_blank" rel="noopener noreferrer">
              <button className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition">
                Get Best Value
              </button>
              </a>
            </div>

            {/* Bulk Tier */}
            <div className="bg-green-50 p-6 rounded-xl border-2 border-green-100">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Bulk</h2>
              <div className="text-4xl font-bold text-green-600 mb-2">$9.99</div>
              <div className="text-gray-600 mb-4">50 Credits</div>
              <div className="text-sm text-gray-500 mb-4">($0.20 per worksheet)</div>
              <div className="text-green-700 text-sm mb-3">
                Save $5 vs 5x Starter packs
              </div>
              <a href="https://app.theworksheep.com/buycredits" target="_blank" rel="noopener noreferrer">
  <button 
    className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition">
    Save Big
  </button>
</a>
            </div>
          </div>

          <p> <strong>Try risk-free: Get 5 free credits when you sign up! 🎉</strong></p>

          <div className="bg-green-100 p-4 rounded-lg mb-8 mt-8">
            <h2 className="text-xl font-semibold text-green-700 mb-4">
              Why Choose Worksheep?
            </h2>
            <ul className="text-left text-gray-700 space-y-2 max-w-2xl mx-auto">
              <li>✅ No subscription required - credits never expire</li>
              <li>✅ Premium worksheet templates included</li>
              <li>✅ Priority customer support</li>
              <li>✅ Secure payment processing</li>
            </ul>
          </div>

          <div className="text-gray-600 text-sm mt-8">
            <p className="mb-2">Need more? Ask about our <strong>Team Plans</strong> for schools!</p>
         
            <div className="mt-12 text-left max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
              Frequently Asked Questions
            </h2>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <Accordion key={index} className="mb-2 shadow-sm">
                  <AccordionSummary
                    expandIcon={<img src={down} alt="expand" className="w-6 h-6" />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant="h6" className="font-semibold text-gray-800">
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="text-gray-600">
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Pricing